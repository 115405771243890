import * as React from 'react';
import { Typography } from '@material-ui/core';

class SidePanelContent extends React.PureComponent {
    public render() {
        return <Typography>This is a side panel.</Typography>;
    }
}

export default SidePanelContent;
